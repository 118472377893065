//@import "./assets/styles/fonts";
@import "./assets/styles/ubuntu_fonts";
@import "./assets/styles/segoe_fonts";
@import "./assets/styles/variables";

// desactive pour IE la croix et l'oeil
::-ms-clear {
  display: none;
}
::-ms-reveal {
  display: none;
}

@font-face {
  font-family:"blogger_Sans";
  src: url('assets/fonts/Blogger_Sans-webfont.eot');
  src: url('assets/fonts/Blogger_Sans-webfont.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:"blogger_SansMedium";
  src: url('assets/fonts/Blogger_Sans-Medium-webfont.eot');
  src: url('assets/fonts/Blogger_Sans-Medium-webfont.woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family:"blogger_SansLight";
  src: url('assets/fonts/Blogger_Sans-Light-webfont.eot');
  src: url('assets/fonts/Blogger_Sans-Light-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Blogger_Sans-Light-webfont.woff') format('woff');
  font-weight: normal;
}

.form-error-message {
  color: $light-red;
  font-size: 11px;
  padding-top: 3px;
}

[data-toggle="collapse"][aria-expanded="true"] > .arrow {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.spinner {
  font-size: 3px;
  margin: 5px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #4095d3;
  background: -moz-linear-gradient(left, #4095d3 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #4095d3 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #4095d3 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #4095d3 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #4095d3 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before {
  width: 50%;
  height: 50%;
  background: #4095d3;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.spinner:after {
  background: #80CCEE;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

$offset: 187;
$duration: 2.5s;

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
          dash $duration ease-in-out infinite,
          colors ($duration*4) ease-in-out infinite;
}
@keyframes colors {
  0% { stroke: #4095d3; }
  100% { stroke: #4095d3; }
}
@keyframes dash {
  0% { stroke-dashoffset: $offset; }
  50% {
    stroke-dashoffset: $offset/4;
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform:rotate(450deg);
  }
}
