@font-face {
  font-family: 'Segoe UI Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Regular'), url('./assets/fonts/Segoe_UI.woff') format('woff');
}

@font-face {
  font-family: 'Segoe UI Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Italic'), url('./assets/fonts/Segoe_UI_Italic.woff') format('woff');
}

@font-face {
  font-family: 'Segoe UI Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Bold'), url('./assets/fonts/Segoe_UI_Bold.woff') format('woff');
}

@font-face {
  font-family: 'Segoe UI Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Bold Italic'), url('./assets/fonts/Segoe_UI_Bold_Italic.woff') format('woff');
}
